import { Component, ReactNode } from "react";
import { Button, Card } from "flowbite-react";
import { HiOutlineRefresh } from "react-icons/hi";
import Error from "src/assets/images/error/error.png";
import { sendErrorAsync } from "src/app/store/features/misc/misc.actions";
import { connect } from "react-redux";
import { RootState } from "src/app/store/root.reducer";
import { getBrowserInfo } from "src/app/utils/helpers";

type Props =
	ReturnType<typeof mapStateToProps>
	& typeof mapDispatchToProps
	& {
		children: ReactNode
	};

type State = {
	hasError: boolean
};

class ReservationErrorBoundary extends Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = { hasError: false };
	}

	static getDerivedStateFromError() {
		return { hasError: true };
	}

	componentDidCatch(error: any, errorInfo: any) {
		this.props.sendError({
			errorBoundary: "Reservation",
			location: this.props.location,
			error: error.toString(),
			browserInfo: getBrowserInfo(),
			errorInfo,
			form: this.props.purchasingProcessForm,
		});
	}

	render() {
		if (this.state.hasError) {
			return (
				<Card className="w-full lg:max-w-[640px] lg:[&>*]:w-full sm:lg:[&>*]:p-4 lg:[&>*]:p-10">
					<div className="flex flex-col items-center justify-center">
						<img src={ Error } className="w-auto max-w-full sm:max-w-md h-[40vh] mb-4" alt="error"/>
						<h1 className="mb-3 w-4/5 text-center font-bold dark:text-white text-xl lg:text-2xl xl:text-4xl">
							Przykro nam, ale wystąpił nieoczekiwany błąd.
						</h1>
						<p className="mb-6 w-4/5 text-center text-base lg:text-lg text-gray-500 dark:text-gray-300">
							Nasz zespół już pracuje nad jego rozwiązaniem. Proszę odświeżyć stronę lub spróbować ponownie później
						</p>
						<Button href="/">
							<div className="mr-1 flex items-center gap-x-2">
								<HiOutlineRefresh className="text-xl"/>
								Odśwież
							</div>
						</Button>
					</div>
				</Card>
			);
		}

		return this.props.children;
	}
}

const mapStateToProps = (state: RootState) => ({
	location: state.router.location,
	purchasingProcessForm: state.form.purchasingProcess,
});

const mapDispatchToProps = {
	sendError: sendErrorAsync.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReservationErrorBoundary);
